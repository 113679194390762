import React from "react"
import Header from "./header"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath


  // This part creates the whole site structure
  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Header />  {/* This row creates the header */}
      <main class="site-content">{children}</main> {/* This row inputs the bio and the blog feed */}
      <footer> © Onni Anttoora</footer> {/*Footer is created here*/}
    </div>
  )
}

export default Layout
